<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="g_cardState(item.active)">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0">
                        <span class="font-weight-bold">
                            <span class="gold--text">{{item.id}}</span>
                        </span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.name }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>

                        <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                            <v-col v-bind="layout" :style="calculateWidthPerItem">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t('sellers_list.table_keys.active_days') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.active_days}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout" :style="calculateWidthPerItem">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('current_salary') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.current_salary}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout" :style="calculateWidthPerItem">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_salaries') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.total_salaries}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout" :style="calculateWidthPerItem">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_rent') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.total_rent}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout" :style="calculateWidthPerItem">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys('total_paid_rent') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.total_paid_rent}}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-col>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="width: calc(100% / 7)">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>

                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ g_isDefined(item[v.value]) }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{item}}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "ProvisionCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        //card: {  xs: 6, sm: 3, md: 3, align: 'center', class: 'my-2'},
        card: { align: 'center', class: 'my-2'},
    }),
    computed: {
        layout() {
            return this.card;
        },
        calculateWidthPerItem() {
            return "width: calc(100% / 7) !important;";
        }
    }
}
</script>